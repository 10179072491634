// Top and left are in percent across the screen - width is in pixels to make things simple.
// All albums assumed to be square - if an album is higher up in the list, it will be a lower z-index.
export const SONG_PLACEMENTS = [{
  name: 'all-the-little-lights.jpg',
  top: 18,
  left: 50,
  width: 6
}, {
  name: 'waiting-for-the-dawn.jpg',
  top: 40,
  left: 77,
  width: 6
}, {
  name: 'ab.jpg',
  top: 81,
  left: 21.5,
  width: 4.5
}, {
  name: 'a-boy-and-his-kite.jpg',
  top: 56,
  left: 85,
  width: 5.25,
}, {
  name: 'after-laughter.jpg',
  top: 3,
  left: 28,
  width: 3.25
}, {
  name: 'AM.jpg',
  top: 8,
  left: 33,
  width: 3.5
}, {
  name: 'an-awesome-wave.jpg',
  top: 39,
  left: 85.25,
  width: 3.5
}, {
  name: 'and-the-war-came.jpg',
  top: 60,
  left: 22,
  width: 5
}, {
  name: 'a-rush-of-blood-to-the-head.jpg',
  top: 66,
  left: 5.5,
  width: 4,
}, {
  name: 'atlas-i.jpeg',
  top: 5,
  left: 59,
  width: 4
}, {
  name: 'beneath-the-brine.jpg',
  top: 40,
  left: 82.5,
  width: 3
}, {
  name: 'beneath-the-skin.jpg',
  top: 64,
  left: 1,
  width: 4
}, {
  name: 'black-panther.jpg',
  top: 14,
  left: 33,
  width: 3.5
}, {
  name: 'blurryface.jpg',
  top: 60,
  left: 39,
  width: 3.5
}, {
  name: 'brother.jpg',
  top: 1.5,
  left: 36.5,
  width: 4,
}, {
  name: 'cardboard-castle.png',
  top: 68,
  left: 66,
  width: 5.5,
}, {
  name: 'celeste.jpg',
  top: 11,
  left: 27,
  width: 4.5,
}, {
  name: 'colours.jpg',
  top: 1.5,
  left: 32,
  width: 3.25,
}, {
  name: 'complaint.jpg',
  top: 53,
  left: 33,
  width: 3.75
}, {
  name: 'death-of-a-bachelor.jpg',
  top: 48,
  left: 41,
  width: 3.5,
}, {
  name: 'first-mind.jpg',
  top: 85.5,
  left: 30,
  width: 3.75,
}, {
  name: 'flower-boy.jpg',
  top: 31,
  left: 9,
  width: 5.5
}, {
  name: 'folklore.png',
  top: 44.5,
  left: 56.25,
  width: 3.5
}, {
  name: 'golden-sun.jpg',
  top: 36,
  left: 81,
  width: 2.5
}, {
  name: 'housefires-iii.jpg',
  top: 9.5,
  left: 41,
  width: 4
}, {
  name: 'illinoise.jpg',
  top: 27,
  left: 19,
  width: 5
}, {
  name: 'in-between-dreams.jpg',
  top: 86,
  left: 72,
  width: 6
}, {
  name: 'in-the-aeroplane-over-the-sea.jpg',
  top: 36,
  left: 74.5,
  width: 2.5
}, {
  name: 'is-there-anybody-out-there.jpg',
  top: 31,
  left: 45.5,
  width: 3.5
}, {
  name: 'la-la-land.png',
  top: 32.5,
  left: 24.25,
  width: 3.5,
}, {
  name: 'let\'s be still.jpg',
  top: 36,
  left: 65,
  width: 3.75
}, {
  name: 'listen-to-the-river.jpg',
  top: 85,
  left: 36,
  width: 4.25
}, {
  name: 'lonesome-dreams.jpg',
  top: 31,
  left: 36.5,
  width: 5
}, {
  name: 'my-head-is-an-animal.jpg',
  top: 35,
  left: 69,
  width: 5.5
}, {
  name: 'native.png',
  top: 18,
  left: 63,
  width:  5
}, {
  name: 'night-visions.jpg',
  top: 31,
  left: 28,
  width: 4.25
}, {
  name: 'now-not-yet.jpg',
  top: 31,
  left: 84,
  width: 5
}, {
  name: 'o-joshua.jpeg',
  top: 20,
  left: 70,
  width: 4.25
}, {
  name: 'our-endless-numbered-days.jpg',
  top: 82,
  left: 60,
  width: 7
}, {
  name: 'parachutes.jpg',
  top: 18,
  left: 41.5,
  width: 4
}, {
  name: 'pretty-odd.jpg',
  top: 65.5,
  left: 57,
  width: 5.5
}, {
  name: 'pure-heroine.jpg',
  top: 10,
  left: 36.75,
  width: 4
}, {
  name: 'repave.jpg',
  top: 35.5,
  left: 77,
  width: 3.5
}, {
  name: 'rumours.jpg',
  top: 64,
  left: 32,
  width: 4.5
}, {
  name: 'seven-sisters.jpg',
  top: 46,
  left: 45.5,
  width: 4.5
}, {
  name: 'shallow-grave.png',
  top: 8,
  left: 64,
  width: 3.75
}, {
  name: 'sigh-no-more.jpg',
  top: 78,
  left: 2,
  width: 5.5
}, {
  name: 'strange-trails.jpg',
  top: 52,
  left: 54,
  width: 5
}, {
  name: 'tenboom.jpg',
  top: 68.5,
  left: 89,
  width: 6
}, {
  name: 'the-brilliance-original-mixtape.jpg',
  top: 2,
  left: 41.5,
  width: 3.5
}, {
  name: 'the-great-minimum.jpg',
  top: 66,
  left: 10,
  width: 10
}, {
  name: 'the-head-and-the-heart.jpg',
  top: 43.75,
  left: 52,
  width: 4
}, {
  name: 'the-human-condition.jpg',
  top: 33,
  left: 0,
  width: 6
}, {
  name: 'the-incredible-true-story.jpg',
  top: 15.5,
  left: 19,
  width: 4.5,
}, {
  name: 'the-kingfisher.jpg',
  top: 76,
  left: 31.5,
  width: 3.75
}, {
  name: 'the-lumineers.jpg',
  top: 71,
  left: 44,
  width: 4
}, {
  name: 'the-outsiders.jpg',
  top: 85,
  left: 43,
  width: 6
}, {
  name: 'the-reckoning.jpg',
  top: 33,
  left: 60.5,
  width: 4.5
}, {
  name: 'the-year-of-hibernation.jpg',
  top: 35,
  left: 93,
  width: 6
}, {
  name: 'through-the-deep-dark-valley.jpg',
  top: 23.5,
  left: 89.5,
  width: 5
}, {
  name: 'to-pimp-a-butterfly.jpg',
  top: 48,
  left: 23,
  width: 5
}, {
  name: 'torches.jpg',
  top: 50,
  left: 12,
  width: 5
}, {
  name: 'towrs.jpg',
  top: 27.5,
  left: 49,
  width: 7
}, {
  name: 'trench.jpg',
  top: 5,
  left: 77,
  width: 5  
}, {
  name: 'true.png',
  top: 19,
  left: 37,
  width: 3.5
}, {
  name: 'vessel.jpg',
  top: 52,
  left: 3.5,
  width: 4.5
}, {
  name: 'vide-noir.jpg',
  top: 21,
  left: 32.5,
  width: 4
}, {
  name: 'viva-la-vida.jpg',
  top: 38,
  left: 16.5,
  width: 5.25
}, {
  name: 'walk-the-moon.png',
  top: 21,
  left: 25.5,
  width: 5
}, {
  name: 'when-we-all-fall-asleep-where-do-we-go.png',
  top: 43,
  left: 32,
  width: 3 
}, {
  name: 'wolfgang-amadeus-phoenix.jpg',
  top: 2.5,
  left: 53.5,
  width: 3
}, {
  name: 'woodland.jpg',
  top: 63.5,
  left: 51,
  width: 4.5
}, {
  name: 'x-infinity.jpg',
  top: 70.5,
  left: 25.5,
  width: 4.5
}, {
  name: 'x.jpg',
  top: 6,
  left: 71,
  width: 4.5
}, {
  name: 'young-mountain.jpg',
  top: 83,
  left: 91,
  width: 6
}, {
  name: 'darlin-oh-darlin.jpg',
  top: 45,
  left: 60,
  width: 4
}, {
  name: 'science-n-faith.jpg',
  top: 48,
  left: 65.5,
  width: 4.5
}, {
  name: 'the-oh-hellos.jpg',
  top: 60,
  left: 45,
  width: 5
}, {
  name: 'if-i-was.jpg',
  top: 33,
  left: 56,
  width: 4.5
}, {
  name: 'funeral.jpg',
  top: 51,
  left: 93.5,
  width: 4.5,
}, {
  name: 'badlands.jpg',
  top: 44,
  left: 88.5,
  width: 4.25
}, {
  name: 'gossamer.jpg',
  top: 8.75,
  left: 52,
  width: 4
}, {
  name: 'hamilton.jpeg',
  top: 61,
  left: 73,
  width: 5.5
}, {
  name: '1989.png',
  top: 43,
  left: 36,
  width: 4
}, {
  name: 'because-the-internet.jpg',
  top: 4,
  left: 21.5,
  width: 4.5
}, {
  name: 'eurus.jpg',
  top: 3,
  left: 91,
  width: 4
}, {
  name: 'how-to-be-a-human-being.jpg',
  top: 71,
  left: 81,
  width: 5.5
}, {
  name: '2014-forest-hills-drive.jpg',
  top: 25,
  left: 14,
  width: 5,
}, {
  name: 'home.jpg',
  top: 72,
  left: 37,
  width: 5
}, {
  name: 'smoke-and-mirrors.jpg',
  top: 84,
  left: 51.5,
  width: 6
}, {
  name: 'talking-is-hard.jpg',
  top: 20,
  left: 79,
  width: 4.5
}, {
  name: 'babel.jpg',
  top: 85,
  left: 84,
  width: 5.5
}];